import { Notification } from '@app/@types/redux/users';
import env from '@config/env';
import logger from '@libs/log';
import { updateNotifications as updateNotificationsFn } from '@state/redux/slices/user.slice';
import { useAppDispatch } from '@state/redux/store';
import classnames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaBan, FaBell, FaChevronUp, FaExclamation } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import useSWR from 'swr';
import Api from '../../../state/utils/Api';
import { translate } from '../../../state/utils/helper';
import {
  Badge,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from '../Html';

function getIcon(event: string) {
  if (event === 'level_up') {
    return FaChevronUp;
  }
  if (event === 'profanity') {
    return FaExclamation;
  }
  if (event === 'plagiarism') {
    return FaBan;
  }
  return FaBell;
}

function Notifications() {
  const fetcher = async () => {
    const response = await Api.getNotifications();
    return response.data.data;
  };

  const [liveNotificationFeed, setLiveNotificationFeed] = useState(true);

  const { data: notifications = [] } = useSWR('notifications', fetcher, {
    refreshInterval: liveNotificationFeed && env.PROD ? 5000 : undefined,
  });

  const dispatch = useAppDispatch();

  async function updateNotifications(notificationIds: string[]) {
    try {
      await dispatch(updateNotificationsFn(notificationIds));
    } catch (error) {
      logger.error('Error updating notifications:');
    }
  }

  function updateReadNotifications() {
    const notificationIds = notifications.map((x) => x.id);
    updateNotifications(notificationIds).catch(() => {
      logger.error('Error updating notifications:');
    });
  }

  const [floatingNotification, setFloatingNotification] = useState(false);

  useEffect(() => {
    if (notifications.length > 0) {
      const unreadNotifications = notifications.filter((x) => !x.attributes.userChecked);
      setFloatingNotification(Boolean(unreadNotifications.length));
    }
  }, [notifications]);

  const unreadNotifications = notifications.filter((x) => !x.attributes.userChecked);

  const unreadNotificationsCount = unreadNotifications.length;

  useEffect(() => {
    if (floatingNotification) {
      setLiveNotificationFeed(false);
    } else {
      setLiveNotificationFeed(true);
    }
  }, [floatingNotification]);

  const navigate = useNavigate();
  const location = useLocation();

  function generateNotification(
    notification: Notification,
    updateNotifications?: (notificationIds: string[]) => Promise<void>,
    floating = false
  ) {
    const {
      id,
      attributes: { event, notifiableType, userChecked, createdAt, data },
    } = notification;
    const NotificationIcon = getIcon(event);
    return (
      <ListGroupItem
        key={id}
        className={classnames(
          userChecked ? 'notification-read' : `notification-unread`,
          data.link ? 'notification-item-pointer' : '',
          `list-group-item-action`,
          { 'floating-notification bg-gradient-default': floating }
        )}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          if (data.link && updateNotifications) {
            updateNotifications([id]).catch(() => {
              logger.error('Error updating notifications');
            });
            // const url = `${window.location.protocol}//${window.location.host}`;
            // window.open(`${url}/${data.link}/notification`, '_self');
            const link = `/${data.link}`;
            if (location.pathname === link) {
              window.location.reload();
            }
            navigate(link);
          }
        }}
        tag="a"
      >
        <Row className="align-items-center">
          <Col className="col-auto">
            <NotificationIcon className={classnames('text-center')} />
          </Col>
          <div className="col ml--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="mb-0 text-sm floating-notification-title">
                  {translate(`notifications.types.${notifiableType}`)}
                </h4>
              </div>
              <div className="text-right text-muted floating-notification-time">
                <small>{moment(createdAt).fromNow()}</small>
              </div>
            </div>
            <p className="text-sm mb-0">
              {translate(`notifications.events.${event}`, {
                stage: data.projectStage || '',
                project: data.project || 'challenge',
              })}
            </p>
          </div>
        </Row>
      </ListGroupItem>
    );
  }

  return (
    <>
      <CSSTransition
        in={floatingNotification}
        timeout={{ enter: 1000, exit: 300 }}
        unmountOnExit
        classNames="notification-slide"
      >
        <div className="floating-notification-container floating">
          <button
            type="button"
            onClick={() => {
              updateNotifications([notifications[0].id]).catch(() => {
                logger.error('Error updating notifications:');
              });

              setFloatingNotification(false);
            }}
          >
            <FiX />
          </button>
          <div>
            {unreadNotificationsCount &&
              generateNotification(unreadNotifications[0], updateNotifications, true)}
          </div>
        </div>
      </CSSTransition>
      <UncontrolledDropdown nav inNavbar className="d-none d-md-block my-auto">
        <DropdownToggle
          nav
          onClick={() => {
            updateReadNotifications();
          }}
          id="notificationsNavbarToolTip"
        >
          <span>
            <FaBell style={{ fontSize: 20 }} />
          </span>
          {unreadNotificationsCount ? (
            <Badge
              color="danger"
              className="badge-md badge-circle badge-floating border-white notification-badge"
            >
              {unreadNotificationsCount}
            </Badge>
          ) : null}
        </DropdownToggle>
        <UncontrolledTooltip delay={0} placement="bottom" target="notificationsNavbarToolTip">
          {translate('layout.navbar.notifications')}
        </UncontrolledTooltip>
        <DropdownMenu className="dropdown-menu-xl mt-2" right>
          <div className="px-3 py-3">
            <h6 className="text-sm text-muted m-0">
              {translate('notifications.unreadPre')}{' '}
              <strong className="text-info">{unreadNotificationsCount}</strong>{' '}
              {translate('notifications.unreadPost')}
            </h6>
          </div>
          <ListGroup flush>
            {notifications
              .sort((a, b) => (a.attributes.createdAt < b.attributes.createdAt ? 1 : -1))
              .slice(0, 5)
              .map((notification) => generateNotification(notification, updateNotifications))}
          </ListGroup>
          <DropdownItem
            className="text-center text-info font-weight-bold py-3"
            to="/notifications"
            tag={Link}
          >
            {translate('notifications.viewAll')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default Notifications;
